import Vue from 'vue'
import Vuex from 'vuex'

// 引入模块
import zdInput from './modules/zdInput'; // 新增的 zdInput 模块

Vue.use(Vuex)

// 创建并导出 Vuex Store 实例
export default new Vuex.Store({
  modules: {
    zdInput,  // 注册 zdInput 模块
  },
});