// store/modules/zdInput.js
import Vue from 'vue'; // 确保引入 Vue
// 定义模块的状态对象，用于存储组件的数据
const state = {
  components: {}, // 存储所有组件的数据
};

// 定义模块的 mutation 函数，用于修改状态
const mutations = {
  // 初始化组件数据
  INIT_COMPONENT(state, { id, data }) {
    if (!state.components[id]) {
      Vue.set(state.components, id, data || {});
    }
  },
  // 更新组件的某个字段
  UPDATE_COMPONENT(state, { id, field, value }) {
    if (state.components[id]) {
      Vue.set(state.components[id], field, value);
    }
  },
  // 设置多个组件的数据
  SET_COMPONENTS(state, components) {
    state.components = { ...state.components, ...components }; // 合并新组件数据到现有组件数据中
  },
};

// 定义模块的 action 函数，用于触发 mutation
const actions = {
  // 初始化组件
  initializeComponent({ commit }, payload) {
    commit('INIT_COMPONENT', payload); // 调用 INIT_COMPONENT mutation
  },
  // 更新组件
  updateComponent({ commit }, payload) {
    commit('UPDATE_COMPONENT', payload); // 调用 UPDATE_COMPONENT mutation
  },
  // 设置多个组件
  setComponents({ commit }, payload) {
    commit('SET_COMPONENTS', payload); // 调用 SET_COMPONENTS mutation
  },
};

// 定义模块的 getter 函数，用于获取状态
const getters = {
  // 获取指定组件的状态
  getComponentState: (state) => (id) => {
    return state.components[id] || {}; // 返回指定组件的数据，如果不存在则返回空对象
  },
  getAllComponents: (state) => {
    return state.components;
  },
};

// 导出模块配置
export default {
  namespaced: true, // 开启命名空间
  state,
  mutations,
  actions,
  getters,
};
