import zh from "./zh";
import en from "./en";
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);


const i18n = new VueI18n({
  legacy: false,
  locale: 'en',  // 初始化配置语言
  messages: {
    en: {
      ...en,
      ...enLocale
    },
    zh: {
      ...zh,
      ...zhLocale
    }
  }

})
//  使用{{ $t("list.create_time") }}
export default i18n