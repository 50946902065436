import request from './http'
// import qs from 'qs';
import store from '@/store/index'

export function fetchUploadPost(url, param, item) {
    param = setCommonParams(param);
    return new Promise((resolve, reject) => {
        request.post(url, param, {
                onUploadProgress(e) {
                    let complete = (e.loaded / e.total * 100 | 0);
                    let data = { complete, item };
                    if (item != undefined) {
                        store.commit('setProgress', data);
                    }
                }
            })
            .then(response => {
                resolve(response.data);
                if (response.data.code == 410) {
                    location.reload()
                    this.$router.push('login')
                        // Util.setCookie("user_token", null);
                        // Util.setCookie("company_id", null);
                        // Util.setCookie("department_id", null);
                        // Util.setCookie("position_id", null);
                        // Util.setCookie("userId", null);
                }
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

function fetchDownloadGet(url, params) {
    params = setCommonParams(params);
    return new Promise((resolve, reject) => {
        request.get(url, { params: params, responseType: 'blob' })
            .then(res => {
                resolve(res);
            }, err => {
                reject(err);
            })
            .catch(err => {
                reject(err);
            })
    })
}

function fetchGet(url, params) {
    params = setCommonParams(params);
    return new Promise((resolve, reject) => {
        request.get(url, {
                params: params
            })
            .then(response => {
                if (response.data == undefined) {
                    resolve(response);
                } else {
                    resolve(response.data);
                }
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

function fetchDelete(url, params) {
    params = setCommonParams(params);
    return new Promise((resolve, reject) => {
        request.delete(url, {
                data: params
            })
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error);
            });
    });
}


export function fetchPost(url, param) {
    param = setCommonParams(param);
    return new Promise((resolve, reject) => {
        request.post(url, param)
            .then(response => {
                console.info('response:', response);
                resolve(response.data);
                if (response.data.code == 410) {
                    location.reload()
                    this.$router.push('login')
                        // Util.setCookie("user_token", null);
                        // Util.setCookie("company_id", null);
                        // Util.setCookie("department_id", null);
                        // Util.setCookie("position_id", null);
                        // Util.setCookie("userId", null);
                }
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function fetchPut(url, param) {
    // param = qs.stringify(param);
    param = setCommonParams(param);
    return new Promise((resolve, reject) => {
        request.put(url, param)
            .then(response => {
                resolve(response.data);
                if (response.data.code == 410) {
                    location.reload()
                    this.$router.push('login')
                        // Util.setCookie("user_token", null);
                        // Util.setCookie("company_id", null);
                        // Util.setCookie("department_id", null);
                        // Util.setCookie("position_id", null);
                        // Util.setCookie("userId", null);
                }
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

let setCommonParams = function(params) {
    if (params == null || typeof(params) == 'undefined') {
        params = {};
    }
    // 判断是否为单文件上传
    if (params.file) {
        let formdata = new FormData();
        formdata.append('file', params.file);
        return formdata
    }
    //判断是否为多文件上传
    if (params.files) {
        let formdata = new FormData();
        params.files.forEach((item) => {
            formdata.append('files', item);
        });
        return formdata
    }
    return params;
}
setCommonParams();
export default {
    fetchGet,
    fetchPost,
    fetchPut,
    fetchDelete,
    fetchDownloadGet,
    fetchUploadPost
}